import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import api from "@/hooks/useApi";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader2 } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { z } from "zod";
import ThanksDialog from "./ThanksDialog";
import { Checkbox } from "@/components/ui/checkbox";
import { useTranslation } from "react-i18next";

interface FormProps {
    language: string;
    landing_page_uuid: string;
    indicator_uuid: string | null;
    utm_source: string | null;
    utm_medium: string | null;
    utm_campaign: string | null;
    product_name: string;
    form_text: {
        title: string;
        button: string;
    };
    colors: {
        primary: string;
        secondary: string;
    }
}

export default function MainPageForm({ form_text, landing_page_uuid, indicator_uuid, utm_source, utm_medium, utm_campaign, language, colors, product_name }: FormProps) {
    const [loading, setLoading] = useState<boolean>(false)
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    const {t} = useTranslation()

    const form_schema = z.object({
        name: z.string().max(60).min(1, {message: t('form.errors.name')}),
        email: z.string().email({message: t('form.errors.email')}).max(100),
        phone_number: z.string().max(30).min(1, {message: t('form.errors.phone_number')}),
        checkbox: z.boolean({required_error: t('form.errors.checkbox')})
            .default(true)
            .refine((value) => value === true)
    })

    const form = useForm<z.infer<typeof form_schema>>({
        resolver: zodResolver(form_schema),
        defaultValues: {
            name: "",
            email: "",
            phone_number: "",
            checkbox: true
        }
    })

    const handleSubmit = async (data: z.infer<typeof form_schema>) => {
        setLoading(true)

        const new_data = {
            name: data.name,
            email: data.email,
            phone_number: data.phone_number
        }

        await api.post("/leads/store", { ...new_data, landing_page_uuid, indicator_uuid, utm_source, utm_medium, utm_campaign, language })
            .then(() => {
                toast.success(t('toast.lead_form.success'))
                setModalOpen(!modalOpen)
                form.reset()
            })
            .catch((error) => {
                if (error.response.data.code == 409) {
                    toast.success(t('toast.lead_form.success'))
                    setModalOpen(!modalOpen)
                    form.reset()
                    return  
                }

                toast.error(t('toast.lead_form.error'))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <>
            <div
                style={{
                    background: `linear-gradient(to bottom right, ${colors.primary}, ${colors.secondary})`
                }}
                className="max-w-[500px] w-full rounded-xl p-[2px]">
                <Form {...form}>
                    <form
                        id="lead-form"
                        onSubmit={form.handleSubmit(handleSubmit)}
                        className="space-y-4 w-full bg-white p-4 rounded-[calc(0.75rem-2px)]"
                    >

                        {/* <h4
                            style={{
                                color: colors.secondary
                            }}
                            className="font-semibold text-center mt-2 mb-4 text-2xl md:text-3xl lg:text-4xl">
                            {form_text.title}
                        </h4> */}

                        <FormField
                            name="name"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem className="text-start">
                                    <FormLabel className="font-extralight">{t("form.labels.name")} *</FormLabel>
                                    <FormControl>
                                        <Input
                                            type="text"
                                            className="bg-[#B3C5D4] focus-visible:ring-[#2076BD] rounded-xl bg-opacity-60 py-6"
                                            placeholder={t("form.placeholders.name")}
                                            disabled={loading}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="email"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem className="text-start">
                                    <FormLabel className="font-extralight">{t("form.labels.email")} *</FormLabel>
                                    <FormControl>
                                        <Input
                                            type="email"
                                            className="bg-[#B3C5D4] focus-visible:ring-[#2076BD] rounded-xl bg-opacity-60 py-6"
                                            placeholder={t("form.placeholders.email")}
                                            disabled={loading}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            name="phone_number"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem className="text-start">
                                    <FormLabel className="font-extralight">{t("form.labels.phone_number")} *</FormLabel>
                                    <FormControl>
                                        <Input
                                            className="bg-[#B3C5D4] focus-visible:ring-[#2076BD] rounded-xl bg-opacity-60 py-6"
                                            placeholder={t("form.placeholders.phone_number")}
                                            disabled={loading}
                                            {...field}
                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            control={form.control}
                            name="checkbox"
                            render={({ field }) => (
                                <FormItem className="space-y-0 pt-4 flex flex-row items-center gap-2">
                                    <FormControl>
                                        <Checkbox
                                            style={{
                                                backgroundColor: field.value === true ? colors.secondary : "#FFFFFF"
                                            }} 
                                            checked={field.value}
                                            onCheckedChange={field.onChange}
                                            aria-label="Checkbox para autorizar receber emails da BrainLegacy Technology"
                                        />
                                    </FormControl>
                                    <FormLabel className="cursor-pointer text-start">
                                        {t("form.labels.checkbox")}
                                    </FormLabel>
                                </FormItem>
                            )}
                        />

                        <div className="flex justify-center pt-4">
                            <Button
                                style={{
                                    background: `linear-gradient(to right, rgb(34, 102, 0), rgb(0, 128, 55))`
                                }}
                                disabled={loading}
                                form="lead-form"
                                type="submit"
                                className="w-full text-lg rounded-xl py-6 px-8 hover:filter hover:brightness-90 transition duration-300">
                                {loading ? <Loader2 className="animate-spin" /> : form_text.button}
                            </Button>
                        </div>
                    </form>
                </Form>
            </div>
            <ThanksDialog colors={colors} open={modalOpen} setOpen={setModalOpen} product_name={product_name} />
        </>

    )
}